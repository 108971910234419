<template>
  <div class="content">
    <div class="content-left">
      <h1>Arbitration</h1>
      <h2>Related Project</h2>

      <router-link :to="'/function?id=' + project.id">
        <card class="content-left-card">
          <p slot="title">{{ project.name }}</p>
          <p><b>Abstract:</b>{{ project.abstractInfo }}</p>
          <p><b>UpdateTime:</b>{{ project.updateTime }}</p>
        </card>
      </router-link>
      <h2>Plaintiff</h2>
      <card>
        <p slot="title">{{ source.name || source.username }}</p>
        <template v-if="arbitration.sourceType == 'project'">
          <p><b>Abstract:</b>{{ source.abstractInfo }}</p>
          <p><b>UpdateTime:</b>{{ source.updateTime }}</p>
        </template>
        <template v-else>
          <p><b>Credit:</b>{{ source.credit }}</p>
        </template>
      </card>
      <h2>Defendant</h2>
      <card>
        <p slot="title">{{ target.name || target.username }}</p>
        <template v-if="arbitration.targetType == 'project'">
          <p><b>Abstract:</b>{{ target.abstractInfo }}</p>
          <p><b>UpdateTime:</b>{{ target.updateTime }}</p>
        </template>
        <template v-else>
          <p><b>Credit:</b>{{ target.credit }}</p>
        </template>
      </card>
      <Divider />
      <template v-if="!voteAlready">
        <Button style="margin: 0 15%" @click="approveModal = true"
          >approve</Button
        >
        <Button type="primary" @click="rejectModal = true">reject</Button>
      </template>

      <Modal
        v-model="approveModal"
        title="Confirm"
        @on-ok="approveOk()"
        @on-cancel="cancel()"
      >
        <div>
          <div style="padding-top: 20px">
            Make sure that you approve his arbitration in this page.
          </div>
        </div>
      </Modal>
      <Modal
        v-model="rejectModal"
        title="Confirm"
        @on-ok="rejectOk()"
        @on-cancel="cancel()"
      >
        <div>
          <div style="padding-top: 20px">
            Make sure that you reject his arbitration in this page.
          </div>
        </div>
      </Modal>
    </div>
    <div class="content-right">
      <card>
        <div>
          <h2>Arbitration reason:</h2>
          {{ arbitration.reason }}
        </div>
        <div>
          <h2>JoinRequests:</h2>
          <div class="codeTable" style="text-align: center">
            <Carousel v-model="checkingRequest" v-if="joinRequests.length">
              <template v-for="join of joinRequests">
                <CarouselItem :key="join.id">
                  <Card
                    style="
                      margin-left: 3%;
                      padding: 10px 5%;
                      margin-top: 30px;
                      margin-bottom: 50px;
                    "
                  >
                    <div style="text-align: left">
                      <div class="join_table_unit">
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            display: inline;
                            padding-right: 15px;
                          "
                        >
                          Applicant:
                        </div>
                        <span style="display: inline">{{ join.username }}</span>
                      </div>

                      <div class="join_table_unit">
                        <div
                          style="
                            margin-bottom: 10px;
                            font-size: 1.025rem;
                            float: left;
                            font-weight: 500;
                            display: inline;
                            padding-right: 15px;
                            width: 100%;
                          "
                        >
                          Related module:
                        </div>
                        <Button
                          v-for="items in join.moduleList"
                          :key="items"
                          style="margin-right: 10px"
                        >
                          {{ items }}
                        </Button>
                      </div>

                      <div class="join_table_unit">
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            display: inline;
                            padding-right: 15px;
                          "
                        >
                          Related Code Percentage:
                        </div>
                        <span style="display: inline"
                          >{{ join.relatedPercentage }}%</span
                        >
                      </div>

                      <div class="join_table_unit">
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            display: inline;
                            padding-right: 15px;
                          "
                        >
                          Estimated Code Percentage for Permission:
                        </div>
                        <span>{{ join.estimatedPercentage }} %</span>
                      </div>

                      <div class="join_table_unit">
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            display: inline;
                            padding-right: 15px;
                          "
                        >
                          Type:
                        </div>
                        <p
                          style="
                            font-size: 1rem;
                            font-weight: 400;
                            display: inline;
                          "
                        >
                          {{ join.type }}
                        </p>
                      </div>

                      <div
                        v-if="join.type === 'add new feature'"
                        class="join_table_unit"
                      >
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            padding-right: 15px;
                            margin-bottom: 5px;
                          "
                        >
                          Function summary:
                        </div>
                        <p>{{ join.functionSummary }}</p>
                      </div>
                      <div
                        v-if="join.type === 'add new feature'"
                        class="join_table_unit"
                      >
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            padding-right: 15px;
                            margin-bottom: 5px;
                          "
                        >
                          Description (user view):
                        </div>
                        <p style="font-size: 14px; color: darkgray">
                          Please provide a concrete description from the
                          perspective of users.
                        </p>
                        <p style="font-size: 14px; color: darkgray">
                          You should give some examples to illustrate this new
                          feature.
                        </p>

                        <p>{{ join.userViewSescription }}</p>
                      </div>
                      <div
                        v-if="join.type === 'add new feature'"
                        class="join_table_unit"
                      >
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            padding-right: 15px;
                            margin-bottom: 5px;
                          "
                        >
                          Feature Technical Plan:
                        </div>
                        <p style="font-size: 14px; color: darkgray">
                          If you can't give technical plan,
                          <a :href="'/#/furtherInfo?id=' + projectId"
                            >you can ask for more detail</a
                          >.
                        </p>

                        <p>{{ join.technicalPlan }}</p>
                      </div>
                      <!-- <div class="join_table_unit">
                     <Radio v-model="read_tag">Radio</Radio>
                 </div>-->
                      <div
                        v-if="join.type === 'fix bug'"
                        class="join_table_unit"
                      >
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            padding-right: 15px;
                            margin-bottom: 5px;
                          "
                        >
                          Bug description:
                        </div>
                        <p style="font-size: 14px; color: darkgray">
                          You can gives a brief overview and don't leave out
                          facts.
                        </p>

                        <p>{{ join.bugDescription }}</p>
                      </div>

                      <div
                        v-if="join.type === 'fix bug'"
                        class="join_table_unit"
                      >
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            padding-right: 15px;
                            margin-bottom: 5px;
                          "
                        >
                          Environment version:
                        </div>
                        <p style="font-size: 14px; color: darkgray">
                          Environment description / version information
                        </p>
                        <p>{{ join.environmentVersion }}</p>
                      </div>

                      <div
                        v-if="join.type === 'fix bug'"
                        class="join_table_unit"
                      >
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            padding-right: 15px;
                            margin-bottom: 5px;
                          "
                        >
                          Bug route case:
                        </div>
                        <p style="font-size: 14px; color: darkgray">
                          One of the very best ways you can report a bug is by
                          showing it to us! Show us the same thing again and
                          demonstrate the thing that goes wrong
                        </p>
                        <p>{{ join.bugRouteCase }}</p>
                      </div>

                      <div
                        v-if="join.type === 'fix bug'"
                        class="join_table_unit"
                      >
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            padding-right: 15px;
                            margin-bottom: 5px;
                          "
                        >
                          Expected results:
                        </div>
                        <p style="font-size: 14px; color: darkgray"></p>
                        <p>{{ join.expectedResults }}</p>
                      </div>

                      <div
                        v-if="join.type === 'fix bug'"
                        class="join_table_unit"
                      >
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            padding-right: 15px;
                            margin-bottom: 5px;
                          "
                        >
                          Screenshot:
                        </div>
                        <div
                          v-for="(items, index) in join.screenshotList"
                          :key="index"
                          style="
                            height: 100px;
                            float: left;
                            margin-right: 15px;
                            border: 1px solid darkgray;
                          "
                        >
                          <!--<img :src="items.src" />-->
                          <img :src="items" height="98" width="98" />
                          <!--<div class="demo-upload-list-cover">
                            <Icon type="ios-eye-outline" @click.native="handleView(items.name)"></Icon>
                            <Icon type="ios-trash-outline" @click.native="handleRemove(items)"></Icon>
                        </div>-->
                          <div
                            style="
                              text-align: center;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: nowrap;
                              font-weight: 500;
                              font-size: 0.83rem;
                            "
                          >
                            {{ index + 1 }}
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="join.type === 'fix bug'"
                        class="join_table_unit"
                      >
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            padding-right: 15px;
                            margin-bottom: 5px;
                          "
                        >
                          Fix plan:
                        </div>
                        <p style="font-size: 14px; color: darkgray">
                          If you don't have enough information to give fix plan,
                          you can apply for
                          <a
                            :href="'/#/furtherInfo?id=' + arbitration.projectId"
                            >more detail</a
                          >.
                        </p>
                        <p>{{ join.fixPlan }}</p>
                      </div>

                      <div class="join_table_unit">
                        <div
                          style="
                            font-size: 1.025rem;
                            font-weight: 500;
                            padding-right: 15px;
                            margin-bottom: 5px;
                          "
                        >
                          Test case description:
                        </div>
                        <p style="font-size: 14px; color: darkgray">
                          Please provide a formal description about changes
                          after your contribution !
                        </p>
                        <p>{{ join.testCase }}</p>
                      </div>
                    </div>
                  </Card>
                </CarouselItem>
              </template>
            </Carousel>
            <div v-else>
              <h1>No Info</h1>
            </div>
          </div>
        </div></card
      >
      <div style="width: 80%; float: left; margin-top: 30px">
        <Divider></Divider>
        <div>
          <div
            style="
              width: 50%;
              float: left;
              font-size: 1.25rem;
              text-align: left;
              font-weight: 500;
              margin-bottom: 20px;
              display: inline;
            "
          >
            Voting details
            <Tag style="margin-left: 3px" type="dot" color="warning">{{
              voteState
            }}</Tag>
          </div>
          <div
            style="
              width: 50%;
              float: left;
              font-size: 1rem;
              text-align: right;
              font-weight: 500;
              margin-bottom: 20px;
              display: inline;
            "
          >
            <CountD v-bind:end-date="finishTime"></CountD>
          </div>
        </div>
        <voting-pic
          v-bind:reject="reject"
          v-bind:pending="pending"
          v-bind:approve="approve"
        ></voting-pic>
        <Divider></Divider>
        <div
          style="
            width: 100%;
            float: left;
            margin-left: 0%;
            padding-left: 0%;
            padding-right: 5%;
            padding-bottom: 50px;
          "
        >
          <div
            style="
              width: 50%;
              float: left;
              padding-right: 20%;
              margin-bottom: 20px;
              text-align: right;
            "
          >
            <Input search placeholder="find center person"></Input>
          </div>
          <div
            style="
              width: 50%;
              float: right;
              margin-bottom: 40px;
              text-align: right;
            "
          >
            <Button
              style="margin-left: 12%"
              type="primary"
              @click="commentModal = true"
              >Add</Button
            >
            <Modal
              v-model="commentModal"
              title="Make a discussion with the applicant"
              @on-ok="commentOk"
            >
              <div>
                <div>
                  <Input
                    v-model="commentContent"
                    type="textarea"
                    placeholder="add more info...."
                    style="margin-top: 10px; width: 80%; margin-left: 10%"
                  />
                </div>
              </div>
            </Modal>
          </div>
          <Divider></Divider>
          <div style="width: 100%; float: left">
            <List item-layout="vertical">
              <div
                v-for="item in voteCommentData"
                :key="item.title"
                style="width: 100%; float: left; min-height: 100px"
              >
                <div style="width: 30%; padding-bottom: 30px; float: left">
                  <div style="text-align: center; width: 100%; float: left">
                    <Avatar icon="ios-person" size="70" />
                  </div>
                  <div
                    style="
                      text-align: center;
                      width: 100%;
                      float: left;
                      margin-top: 20px;
                      font-weight: 500;
                      font-size: 1.025rem;
                    "
                  >
                    {{ item.userName }}
                  </div>
                </div>
                <List item-layout="vertical">
                  <ListItem
                    style="
                      text-align: left;
                      width: 70%;
                      float: left;
                      word-wrap: break-word;
                      word-break: normal;
                    "
                  >
                    {{ item.content }}
                  </ListItem>
                  <ListItem
                    style="
                      text-align: right;
                      width: 70%;
                      height: 10px;
                      float: left;
                    "
                  >
                    {{ item.time }}
                  </ListItem>
                </List>
                <Divider></Divider>
              </div>
            </List>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VotingPic from "../components/votingPic.vue";
export default {
  components: { VotingPic },
  data() {
    return {
      arbitration: {},
      project: {},
      source: {},
      target: {},
      joinRequests: [],
      checkingRequest: 0,
      voteId: "",
      approve: 0,
      reject: 0,
      pending: 1,
      voteState: "",
      startTime: "",
      finishTime: "",
      state: "",
      voteCommentData: [],
      rejectModal: false,
      approveModal: false,
      commentModal: false,
      commentContent: "",
    };
  },
  methods: {
    addComment() {},
    getVoteCommentData() {
      this.$axios({
        method: "post",
        url: "/vote/getVoteComment",
        data: {
          voteId: this.jid,
          voteAlready: false,
        },
      })
        .then((response) => {
          let data = response.data.data.items;
          this.voteCommentData = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVoteInforamtion() {
      let data = {
        relatedId: this.jid,
        type: "JoinArbitration",
      };
      this.$axios({
        method: "post",
        url: "/vote/getVoteInformation",
        data: data,
      })
        .then((response) => {
          let data = response.data.data.items;
          this.voteId = data.id;
          this.approve = data.approve;
          this.reject = data.reject;
          this.voteState = data.state;
          this.startTime = data.startTime;
          this.finishTime = data.finishTime;
          this.state = data.state;
          if (this.state === "Done") {
            this.voteAlready = true;
            Object.freeze(this.voteAlready);
          }
          this.getVoteCommentData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    approveOk() {
      let Tself = this;
      let voteData = {
        relatedId: this.jid,
        choice: "approve",
        type: "JoinArbitration",
        userId: this.getCookie("user_id"),
      };
      this.$axios({
        method: "post",
        url: "/vote/submitVote",
        data: voteData,
      })
        .then((response) => {
          let data = response.data.data.items;
          if (data === "vote success") {
            Tself.$Message.success(data);
            this.voteAlready = true;
            this.choice = "approve";
            this.getVoteInforamtion();
          } else {
            Tself.$Message.error(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    rejectOk() {
      let Tself = this;
      let voteData = {
        relatedId: this.jid,
        choice: "reject",
        type: "JoinArbitration",
        userId: this.getCookie("user_id"),
      };
      this.$axios({
        method: "post",
        url: "/vote/submitVote",
        data: voteData,
      })
        .then((response) => {
          let data = response.data.data.items;
          if (data === "vote success") {
            Tself.$Message.success(data);
            this.voteAlready = true;
            this.choice = "reject";
            this.getVoteInforamtion();
          } else {
            Tself.$Message.error(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    commentOk() {
      let data = {
        voteId: this.voteId,
        userId: this.getCookie("user_id"),
        content: this.commentContent,
      };
      this.$axios({
        method: "post",
        url: "/vote/addVoteComment",
        data: data,
      })
        .then((response) => {
          let data = response.data.data.items;
          if (data === "comment success") {
            this.$Message.success(data);
            this.getVoteCommentData();
          } else {
            this.$Message.error(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async mounted() {
    this.jid = this.$route.query.id;
    try {
      let res = await this.$axios.get(
        `/arbitration/getArbitration?arbitrationId=${this.$route.query.id}`
      );
      this.arbitration = res.data.data.items;
      if (this.arbitration.joinRequests) {
        for (let id of this.arbitration.joinRequests) {
          (async () => {
            const res = await this.$axios({
              headers: {
                "Content-Type": "application/text",
              },
              method: "post",
              url: "/join/findById",
              data: id,
            });
            this.joinRequests.push(res.data.data.items);
          })();
        }
      }
      if (this.arbitration.projectId) {
        (async () => {
          res = await this.$axios({
            headers: {
              "Content-Type": "application/text",
            },
            method: "post",
            url: "/project/findById",
            data: this.arbitration.projectId,
          });
          this.project = res.data.data.items;
          console.log(this.project);
        })();
      }
      if (this.arbitration.sourceId) {
        (async () => {
          res = await this.$axios({
            headers: {
              "Content-Type": "application/text",
            },
            method: "post",
            url: `/${this.arbitration.sourceType}/findById`,
            data: this.arbitration.sourceId,
          });
          this.source = res.data.data.items;
        })();
      }

      if (this.arbitration.targetId) {
        (async () => {
          res = await this.$axios({
            headers: {
              "Content-Type": "application/text",
            },
            method: "post",
            url: `/${this.arbitration.targetType}/findById`,
            data: this.arbitration.targetId,
          });
          this.target = res.data.data.items;
        })();
      }
      console.log(this.source, this.target);

      this.getVoteCommentData();
      this.getVoteInforamtion();
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style scoped lang="less">
.content {
  text-align-last: left;
  padding: 50px;
  display: flex;
  flex-direction: row;
  &-left {
    margin: 0 50px;
    &-card {
      width: 300px;
    }
    h2 {
      margin-top: 30px;
    }
  }
  &-right {
    width: 70%;
  }
}
</style>
